import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebaseConfig';
import './LoginPage.css';

const LoginPage = () => {
    let history = useHistory();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                history.push("/blogs");
            }
        });
    }, [history]);

    const login = async (event) => {
        event.preventDefault();
        try {
            await signInWithEmailAndPassword(auth, email, password);
            history.push("/blogs");
        } catch (error) {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.error(errorCode, errorMessage);
        }
    }

    return (
        <div className="loginContainer">
            <form className="loginForm" onSubmit={login}>
                {/* <label htmlFor="email">Email:</label> */}
                <input type="email" id="email" onChange={(e) => setEmail(e.target.value)} />
                {/* <label htmlFor="password">Password:</label> */}
                <input type="password" id="password" onChange={(e) => setPassword(e.target.value)} />
                {/* <button type="submit">Login</button> */}
                <button type="submit">Hello World</button>
            </form>
        </div>
    );
}

export default LoginPage;
