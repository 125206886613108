// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: env.apiKey,
//   authDomain: env.authDomain,
//   projectId: env.projectId,
//   storageBucket: env.storageBucket,
//   messagingSenderId: env.messagingSenderId,
//   appId: env.appId,
//   measurementId: env.measurementId
// };

const firebaseConfig = {
  apiKey: "AIzaSyCk6CE8KVn1TC1Py3mcEVSlgkpC4-lPRIQ",
  authDomain: "blog-9d632.firebaseapp.com",
  projectId: "blog-9d632",
  storageBucket: "blog-9d632.appspot.com",
  messagingSenderId: "12101394961",
  appId: "1:12101394961:web:24ae7ebd2b22ee8e8d87b0",
  measurementId: "G-9K7YTDYYN2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Get the Auth service for the default app
const auth = getAuth();

// Get a Firestore instance
const db = getFirestore();

export { auth, db, analytics, app };
