import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { collection, getDocs, doc, deleteDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";
import DateFormatter from "../DateFormatter";

import "./BlogListPage.css";

function BlogListPage() {
	const [blogs, setBlogs] = useState([]);

	useEffect(() => {
		fetchBlogs();
	}, []);

	const fetchBlogs = async () => {
		const blogCollection = collection(db, "blogs");
		const blogSnapshot = await getDocs(blogCollection);
		const blogList = blogSnapshot.docs.map((doc) => {
			return { id: doc.id, ...doc.data() };
		});
		blogList.sort(function (a, b) {
			var keyA = new Date(a.createdAt),
			keyB = new Date(b.createdAt);
			if (keyA < keyB) return 1;
			if (keyA > keyB) return -1;
			return 0;
		});

		setBlogs(blogList);
	};

	const deleteBlog = async (id) => {
		await deleteDoc(doc(db, "blogs", id));
		fetchBlogs();
	};

	return (
		<div className="blog-list">
			{blogs &&
				blogs.map((blog, index) => {
					return (
						<div key={index} className="blog-item">
							<Link to={`/blogs/${blog.id}`}>
								<h2 className="blog-title">{blog.title}</h2>
							</Link>
							<p>{blog.content.substring(0, 90)}...</p>
							<DateFormatter timestamp={blog.createdAt} />
							<br />
							<button className="delete-button" onClick={() => deleteBlog(blog.id)}>
								Delete
							</button>
						</div>
					);
				})}
		</div>
	);
}

export default BlogListPage;
