import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { collection, addDoc } from "firebase/firestore";
import { db } from '../firebaseConfig';
import './CreateBlogPage.css';

function CreateBlogPage() {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const history = useHistory();

  const createBlog = async () => {
    try {
      await addDoc(collection(db, "blogs"), {
        title: title,
        content: content,
        createdAt: new Date().toISOString(),
      });
      history.push('/blogs');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="create-blog-form">
      <input
        type="text"
        placeholder="Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <textarea
        rows="10" // Change this value to make the content box bigger
        placeholder="Content"
        value={content}
        onChange={(e) => setContent(e.target.value)}
      />
      <button onClick={createBlog}>Create Blog</button>
    </div>
  );
}

export default CreateBlogPage;
