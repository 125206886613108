import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import LoginPage from './components/LoginPage';
import BlogListPage from './components/BlogListPage';
import BlogPage from './components/BlogPage';
import CreateBlogPage from './components/CreateBlogPage';
import Navbar from './components/Navbar';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebaseConfig';
import './App.css';

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    onAuthStateChanged(auth, setUser);
  }, []);

  return (
    <Router>
      <div className="App">
        {user && <Navbar />}
        <Switch>
          <Route exact path="/">
            {user ? <Redirect to="/blogs" /> : <LoginPage />}
          </Route>
          <Route exact path="/blogs" component={BlogListPage} />
          <Route path="/blogs/:id" component={BlogPage} />
          <Route path="/create">
            {user ? <CreateBlogPage /> : <Redirect to="/" />}
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
