import React from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
    return (
        <nav className="navbar">
            <div className="navbar-header">
                <h2 className="navbar-title">Blogs</h2>
            </div>
            <ul className="navbar-nav">
                <li className="nav-item">
                    <NavLink exact to="/blogs" activeClassName="active">View Blogs</NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to="/create" activeClassName="active">Create Blog</NavLink>
                </li>
            </ul>
        </nav>
    );
};

export default Navbar;
