import React, { useEffect, useState } from 'react';
import { db } from '../firebaseConfig';
import { doc, getDoc } from "firebase/firestore";
import './BlogPage.css';
import DateFormatter from "../DateFormatter";

function BlogPage({ match }) {
  const [blog, setBlog] = useState({});

  useEffect(() => {
    fetchBlog();
  });

  const fetchBlog = async () => {
    // Fetch blog from firestone using the id from the url and set it to the blog state

    const docRef = doc(db, "blogs", match.params.id);
    const docSnap = await getDoc(docRef);
    setBlog(docSnap.data());
  };

  return (
    <div className="blog-content">
      <h1 className="blog-title">{blog.title}</h1>
      <p>{blog.content}</p>
      <DateFormatter timestamp={blog.createdAt} />
    </div>
  );
}

export default BlogPage;
