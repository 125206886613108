import React from "react";
import format from "date-fns/format";

const DateFormatter = ({ timestamp }) => {
  if (!timestamp) {
    return <div>Date</div>;
  }

  const date = new Date(timestamp);
  const formattedDate = format(date, "EEEE, MMM do, yyyy");

  return <div>{formattedDate}</div>;
};

export default DateFormatter;
